// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-latin-fever-for-ladies-js": () => import("./../../../src/pages/latin-fever-for-ladies.js" /* webpackChunkName: "component---src-pages-latin-fever-for-ladies-js" */),
  "component---src-pages-nedelni-tancirna-js": () => import("./../../../src/pages/nedelni-tancirna.js" /* webpackChunkName: "component---src-pages-nedelni-tancirna-js" */),
  "component---src-pages-novinky-2022-02-22-pozvanka-na-kurz-latin-fever-js": () => import("./../../../src/pages/novinky/2022-02-22-pozvanka-na-kurz-latin-fever.js" /* webpackChunkName: "component---src-pages-novinky-2022-02-22-pozvanka-na-kurz-latin-fever-js" */),
  "component---src-pages-novinky-2022-03-01-pomoc-ukrajine-js": () => import("./../../../src/pages/novinky/2022-03-01-pomoc-ukrajine.js" /* webpackChunkName: "component---src-pages-novinky-2022-03-01-pomoc-ukrajine-js" */),
  "component---src-pages-novinky-2022-03-07-podekovani-ucastnikum-tancirny-js": () => import("./../../../src/pages/novinky/2022-03-07-podekovani-ucastnikum-tancirny.js" /* webpackChunkName: "component---src-pages-novinky-2022-03-07-podekovani-ucastnikum-tancirny-js" */),
  "component---src-pages-novinky-2022-03-10-registrace-na-novy-kurz-otevrena-js": () => import("./../../../src/pages/novinky/2022-03-10-registrace-na-novy-kurz-otevrena.js" /* webpackChunkName: "component---src-pages-novinky-2022-03-10-registrace-na-novy-kurz-otevrena-js" */),
  "component---src-pages-novinky-2022-04-08-novy-tanecni-kurz-js": () => import("./../../../src/pages/novinky/2022-04-08-novy-tanecni-kurz.js" /* webpackChunkName: "component---src-pages-novinky-2022-04-08-novy-tanecni-kurz-js" */),
  "component---src-pages-novinky-2022-06-12-podzimni-tanecni-startuji-v-rijnu-js": () => import("./../../../src/pages/novinky/2022-06-12-podzimni-tanecni-startuji-v-rijnu.js" /* webpackChunkName: "component---src-pages-novinky-2022-06-12-podzimni-tanecni-startuji-v-rijnu-js" */),
  "component---src-pages-novinky-2022-09-20-registrace-na-novy-tanecni-kurz-otevrena-js": () => import("./../../../src/pages/novinky/2022-09-20-registrace-na-novy-tanecni-kurz-otevrena.js" /* webpackChunkName: "component---src-pages-novinky-2022-09-20-registrace-na-novy-tanecni-kurz-otevrena-js" */),
  "component---src-pages-novinky-2022-12-03-registrace-na-novy-tanecni-kurz-otevrena-js": () => import("./../../../src/pages/novinky/2022-12-03-registrace-na-novy-tanecni-kurz-otevrena.js" /* webpackChunkName: "component---src-pages-novinky-2022-12-03-registrace-na-novy-tanecni-kurz-otevrena-js" */),
  "component---src-pages-novinky-2023-03-11-registrace-na-novy-tanecni-kurz-otevrena-js": () => import("./../../../src/pages/novinky/2023-03-11-registrace-na-novy-tanecni-kurz-otevrena.js" /* webpackChunkName: "component---src-pages-novinky-2023-03-11-registrace-na-novy-tanecni-kurz-otevrena-js" */),
  "component---src-pages-novinky-2023-08-18-podzimni-tanecni-startuji-v-rijnu-js": () => import("./../../../src/pages/novinky/2023-08-18-podzimni-tanecni-startuji-v-rijnu.js" /* webpackChunkName: "component---src-pages-novinky-2023-08-18-podzimni-tanecni-startuji-v-rijnu-js" */),
  "component---src-pages-novinky-2023-08-31-tanecni-kurzy-pro-deti-ve-zlonine-js": () => import("./../../../src/pages/novinky/2023-08-31-tanecni-kurzy-pro-deti-ve-zlonine.js" /* webpackChunkName: "component---src-pages-novinky-2023-08-31-tanecni-kurzy-pro-deti-ve-zlonine-js" */),
  "component---src-pages-novinky-2023-12-11-registrace-na-novy-tanecni-kurz-otevrena-js": () => import("./../../../src/pages/novinky/2023-12-11-registrace-na-novy-tanecni-kurz-otevrena.js" /* webpackChunkName: "component---src-pages-novinky-2023-12-11-registrace-na-novy-tanecni-kurz-otevrena-js" */),
  "component---src-pages-novinky-2024-03-17-registrace-na-novy-tanecni-kurz-otevrena-js": () => import("./../../../src/pages/novinky/2024-03-17-registrace-na-novy-tanecni-kurz-otevrena.js" /* webpackChunkName: "component---src-pages-novinky-2024-03-17-registrace-na-novy-tanecni-kurz-otevrena-js" */),
  "component---src-pages-novinky-2024-08-10-pozvanka-na-kurz-latin-fever-js": () => import("./../../../src/pages/novinky/2024-08-10-pozvanka-na-kurz-latin-fever.js" /* webpackChunkName: "component---src-pages-novinky-2024-08-10-pozvanka-na-kurz-latin-fever-js" */),
  "component---src-pages-novinky-2024-08-11-registrace-na-novy-tanecni-kurz-otevrena-js": () => import("./../../../src/pages/novinky/2024-08-11-registrace-na-novy-tanecni-kurz-otevrena.js" /* webpackChunkName: "component---src-pages-novinky-2024-08-11-registrace-na-novy-tanecni-kurz-otevrena-js" */),
  "component---src-pages-novinky-2024-08-12-tanecni-kurzy-pro-deti-ve-zlonine-js": () => import("./../../../src/pages/novinky/2024-08-12-tanecni-kurzy-pro-deti-ve-zlonine.js" /* webpackChunkName: "component---src-pages-novinky-2024-08-12-tanecni-kurzy-pro-deti-ve-zlonine-js" */),
  "component---src-pages-prihlaska-deti-js": () => import("./../../../src/pages/prihlaska-deti.js" /* webpackChunkName: "component---src-pages-prihlaska-deti-js" */),
  "component---src-pages-tanecni-kurz-pro-deti-js": () => import("./../../../src/pages/tanecni-kurz-pro-deti.js" /* webpackChunkName: "component---src-pages-tanecni-kurz-pro-deti-js" */),
  "component---src-pages-tanecni-pripravka-pro-deti-js": () => import("./../../../src/pages/tanecni-pripravka-pro-deti.js" /* webpackChunkName: "component---src-pages-tanecni-pripravka-pro-deti-js" */),
  "component---src-pages-tanecni-pro-dospele-js": () => import("./../../../src/pages/tanecni-pro-dospele.js" /* webpackChunkName: "component---src-pages-tanecni-pro-dospele-js" */)
}

